import React from 'react';
import workers from '../Assets/workers.png';
import sketchedImage from '../Assets/sketchedImage.png';
import partyImage from '../Assets/partyImage.png';
import pieChart from '../Assets/pieChart.png';

const Body2 = () => {
  return (
    <div className="bg-white p-4">
      <div className='container lg:mx-12 xl:mx-12 md:mx-12'>
        <h1 className='text-center text-5xl font-bold md:text-5xl lg:text-5xl xl:text-5xl font-raleway ml-4 mb-8 md:text-center justify-between'>
          Discover <span className="text-pink-400">Partypal.</span>
        </h1>
        <div className="grid grid-cols-1 mt-12  mx-6 justify-between md:ml-8 lg:ml-8 md:grid-cols-2 lg:grid-cols-2 gap-12 md:gap-20">
          {/** image 1 */}
          <div>
            <img src={workers} alt='workers' className='w-32 lg:w-32 ml-20 md:ml-2 items-center xl:w-32 mb-3'/>
            <h2 className='text-blue-950 font-raleway text-3xl md:text-4xl lg:text-4xl mb-4 md:text-left text-center font-semibold'>
              Event Creation and Promotion Partnerships
            </h2>
            <p className='text-blue-800 text-2xl leading-9 lg:leading-relaxed md:leading-relaxed xl:leading-relaxed md:text-3xl lg:text-4xl md:text-left text-center font-raleway'>
              Create your events with partypal, Boost your event's visibility by partnering with us for featured promotions and advertising opportunities.
            </p>
          </div>

          {/** image 2 */}
          <div className='grid'>
            <img src={sketchedImage} alt='workers' className='w-80 md:w-fit lg:w-fit'/>
          </div>
           
           {/** image 3 */}
          
           <div className=''>
            <img src={pieChart} alt='workers' className='w-32 lg:w-32 ml-20 justify-between md:ml-2 items-center xl:w-32  '/>
            <h2 className='text-blue-950 font-raleway md:text-left mt-6 text-center text-3xl lg:text-4xl md:text-4xl xl:mt-6 lg:mt-6 font-semibold'>
              Data Statistics & Insight for Promoters.
            </h2>
            <p className='text-blue-800 leading-9 mt-6 lg:leading-relaxed md:leading-relaxed xl:leading-relaxed xl:mt-6 lg:mt-6 text-2xl md:text-left md:text-3xl lg:text-4xl text-center font-raleway'>
              Leverage our data analytics services to gain valuable insights into user trends, and event performance.
            </p>
          </div>

          {/** image 4 */}
         
          <div className='grid'>
            <img src={partyImage} alt='workers' className='w-80 md:w-fit lg:w-fit'/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body2;
