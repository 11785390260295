import React from 'react';
import Header from './components/Header';
import Body from  './components/Body';
import Footer from './components/Footer';
import Body2 from './components/Body2';
import Body3 from './components/Body3';
import Body4 from './components/Body4';

function App() {
  return (
    <div className="App">
      <Header/>
      <Body/>
      <Body2/>
      <Body3 />
      <Body4 />
      <Footer/>
    </div>
  );
}

export default App;
