import React, { useState } from 'react';
import Modal from './Modal';
import footerImage from '../Assets/footerImage.png';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value); // Update the email state as the user types
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const response = await fetch("https://partypalbe-dev.up.railway.app/wishlist/add-to-wishlist", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set the content type to JSON
          "api-key": "party-pal",
          "access-key": "abcde12345"
        },
        body: JSON.stringify({
          email })
      });

      console.log(response);

      if (response.status === 201) {
        setMessage('Email submitted successfully!');
        setEmail('');
        setShowModal(true);
      } else {
        setMessage('Failed to submit email. Please try again later.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again later.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="bg-black p-4">
      <div className="container flex flex-col-reverse px-8 items-center mx-auto mt-2 space-y-0 md:space-y-0 md:flex-row">
        {/* Left Item */}
        <div className="flex flex-col ml-10 mt-4 justify-between space-y-3 md:w-1/2">
          <p className='text-white max-w-md font-raleway justify-between items-center ml-4 text-3xl'>Be First in Line for the Nightlife Revolution</p>
          <form onSubmit={handleSubmit}>
            <div className="relative">
              <input
                type="email"
                placeholder="Enter Email"
                value={email}
                onChange={handleEmailChange}
                className="w-40 md:w-48 p-2 m-2 rounded-2xl border text-black border-white focus:outline-none focus:ring focus:border-blue-300"
                disabled={submitting}
              />
              <button className="bg-pink-500 text-white p-2 rounded-2xl hover:bg-pink-600" disabled={submitting}>
                {submitting ? 'Submitting...' : 'Join Waitlist'}
              </button>
            </div>
          </form>
          <p className="text-white">{message}</p>
          <div className="flex ml-4 space-x-4">
            {/* Instagram Link */}
            <a href="https://instagram.com/partypalng?igshid=NGVhN2U2NjQ0Yg%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer" className="text-white font-raleway bg-pink-500 border-2 px-8 p-2 rounded-full">
              Instagram
            </a>
            {/* Twitter Link */}
            <a href="www.tiktok.com/@partypalng" target="_blank" rel="noopener noreferrer" className="text-white bg-blue-300 font-raleway border-2 px-8 p-2 rounded-full">
              Tiktok
            </a>
          </div>
        </div>
        {/* Right Item with Image */}
        <div className="w-1/2 mb-24">
          <img src={footerImage} alt="Right Item Image" />
        </div>
      </div>
       {/* Pass showModal prop and onClose function to Modal */}
       <Modal showModal={showModal} onClose={toggleModal} />
    </div>
  );
};

export default Footer;
