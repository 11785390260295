import React, { useState, useEffect } from "react";
import modalImage from "../Assets/modalImage.png"; // Replace with the actual path to your image

const Modal = ({ showModal, onClose }) => {
  const [modal, setModal] = useState(showModal);

  useEffect(() => {
    setModal(showModal);
  }, [showModal]);

  const toggleModal = () => {
    setModal(!modal);
    onClose(); // Close the modal on button click
  };

  useEffect(() => {
    if (modal) {
      document.body.classList.add("active-modal");
    } else {
      document.body.classList.remove("active-modal");
    }
  }, [modal]);

  return (
    <>
      {modal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            onClick={toggleModal}
            className="fixed inset-0 bg-black opacity-50"
          ></div>
          <div className="relative bg-pink-700 p-4 rounded-lg flex flex-col items-center justify-center text-white w-2/3 md:w-1/2 lg:w-1/3">
            <img
              src={modalImage}
              alt="Modal Image"
              className="mb-4 rounded-md"
              style={{ maxWidth: "100%", height: "auto" }}
            />
            <p className="mb-2 text-md font-inter text-center">
              You will be the first to get Partypal App!
            </p>
            <p className="mb-4 text-md font-inter text-center">
              Check your inbox for confirmation.
            </p>
            <button
              className="text-white bg-pink-700 rounded-md py-2 px-3 border border-white hover:bg-pink-400 focus:outline-none focus:ring focus:border-blue-300 text-base w-full h-auto"
              onClick={toggleModal}
              style={{ fontSize: "1.5rem" }}
            >
              Okay!
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
