import React from 'react';
import PinkCamera from '../Assets/PinkCamera.png';
import image1 from '../Assets/image1.png';
import image2 from '../Assets/image2.png';
import image3 from '../Assets/image3.png';
import image4 from '../Assets/image4.png';

const Body3 = () => {
  return (
    <div className="bg-white p-4">
      <div className="container p-4 md:ml-5 mt-8 md:mt-8 bg-black rounded-lg overflow-x-auto">
        <div className="flex mx-2 mt-7 ml-2 pt-2">
          <h1 className="text-2xl text-white mx-auto font-raleway font-semibold  md:text-5xl">Sneak Peek into <span className="text-pink-400">Partypal</span> App</h1>
          <img src={PinkCamera} alt="camera" className="w-16 h-1/6 right-96 ml-4 md:mr-12" />
        </div>
        <div className="slider-container space-x-8 p-8 md:ml-8 ml-2 md:space-x-12 lg:space-x-12">
          <div className="flex-shrink-0 small-screen-slide">
            <img src={image1} alt="Image 1" className="w-32 md:w-44 lg:w-60" />
            <p className="text-white mt-2 font-inter text-center">Home Screen</p>
          </div>
          <div className="flex-shrink-0 small-screen-slide">
            <img src={image2} alt="Image 2" className="w-32 md:w-44 lg:w-60" />
            <p className="text-white mt-2 font-inter text-center">Moments</p>
          </div>
          <div className="flex-shrink-0 small-screen-slide">
            <img src={image3} alt="Image 3" className="w-32 md:w-44 lg:w-60" />
            <p className="text-white mt-2 font-inter text-center">Heat Map</p>
          </div>
          <div className="flex-shrink-0 small-screen-slide">
            <img src={image4} alt="Image 4" className="w-32 md:w-44 lg:w-60" />
            <p className="text-white mt-2 font-inter text-center">Places Listing</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body3;