import React from 'react';
import Iphones from '../Assets/Iphones.png';

const Body4 = () => {
  return (
    <div className="bg-white p-4">
      <div className="container flex flex-col px-8 items-center mt-2 space-y-0 md:space-y-0 md:flex-row">
        {/* Left Item */}
        <div className="flex flex-col mb-12 space-y-6 md:space-y-6 lg:space-y-6 md:w-1/2">
          <h1 className="max-w-md text-4xl font-raleway font-semibold text-center lg:mt-6 md:text-5xl lg:text-6xl xl:text-7xl md:text-left">
          Social <span className="text-pink-400">Connectivity</span>.
          </h1>
          <p className="max-w-sm leading-9 lg:leading-relaxed xl:leading-relaxed text-blue-800 text-2xl font-inter md:text-3xl lg:text-2xl xl:text-4xl md:font-raleway text-center md:text-left">
          Connect with friends, Plan your outings, and share the excitement of your Nightlife adventures through our seamless Social features.
          </p>
        </div>
        {/* Right Item with Image */}
        <div className="w-fit lg:w-fit mb-24 md:ml-44 lg:ml-44">
          <img src={Iphones} alt="Right Item Image"/>
        </div>
      </div>
    </div>
  )
}

export default Body4
