import React from 'react';
import Card1 from '../Assets/Card1.png';
import Card2 from '../Assets/Card2.png';
import Card3 from '../Assets/Card3.png';
import coffee from '../Assets/coffee.png';


const Body = () => {
  return (
    <div className="bg-white p-4 overflow-auto md:flex">
      <div className="container max-w-full mt-8 md:mx-4 mx-auto  rounded-lg overflow-x-auto">
        <div className="justify-between items-center mt-4 pt-4">
        <h1 className='text-center text-5xl font-bold md:text-5xl lg:text-5xl xl:text-5xl font-raleway mb-8 md:text-center  justify-between'>Explore the Hottest <span className="text-pink-400">Events</span></h1>
        </div>
        
         <div className=" items-center justify-between mt-4  md:flex grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 mx-3 ">
          <div className=" justify-between ml-8 mt-4 md:ml-1">
              <img className="w-72 md:w-full md:h-full" src={Card1} alt="Card 1" />
            </div>
            
             <div className='grid justify-between ml-4 md:ml-1'>
            <div className=" mt-6 md:ml-4">
              <img className="w-72 h-32 md:w-fit md:h-full" src={Card2} alt="Card 2" />
            </div>

            <div className="mt-6 md:ml-4">
              <img className="w-72 h-32 md:w-full md:h-full" src={Card3} alt="Card 3" />
            </div>
            </div>
          </div>
        </div>

      </div>
    
  );
};

export default Body;