// Header.js
import React, { useState } from 'react';
import Modal from './Modal'; // Adjust the import path accordingly
import logo from '../Assets/headerLogo.png';
import backgroundImage from '../Assets/herobanner.png';
import headerImage from '../Assets/header-image.png';

const Header = () => {
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const headerStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const response = await fetch("https://partypalbe-dev.up.railway.app/wishlist/add-to-wishlist", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": "party-pal",
          "access-key": "abcde12345"
        },
        body: JSON.stringify({
          email
        })
      });

      if (response.ok) {
        setMessage('Email submitted successfully!');
        setEmail('');
        setShowModal(true);
      } else if (response.status === 400) {
        setMessage('Invalid email. Please provide a valid email address.');
      } else {
        setMessage('Failed to submit email. Please try again later.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again later.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="p-4" style={headerStyle}>
        <nav>
          <div className="container flex justify-between items-center">
            <div className="pt-2 w-32 md:ml-8">
              <img src={logo} alt="logo" />
            </div>
          </div>
        </nav>
        <div className="container flex flex-col md:flex-row items-center mx-auto md:px-8">
          <div className="flex flex-col mt-6 md:mt-12 space-y-6 md:space-y-3 lg:space-y-6 xl:space-y-6 p-4 md:w-1/2">
            <h1 className="text-3xl md:text-4xl lg:text-4xl xl:text-5xl font-semibold text-white mb-4 text-center md:text-left">
              Welcome to the Party Scene Revolution!
            </h1>
            <p className="max-w-md text-xl md:text-xl lg:text-2xl xl:text-2xl text-white text-center md:text-left">
              Get ready to ignite your nights, unveil electrifying experiences, and rock the city with Partypal. Your ultimate backstage pass to the wildest events and the trendiest hotspots. Let's set the night on fire.
            </p>
            <div className="text-center md:text-left md:mr-3 md:mt-2 mb-3 lg:mr-7 xl:mr-10">
              <form onSubmit={handleSubmit}>
                <div className="relative grid items-center">
                  <input
                    type="email"
                    placeholder="Enter Email"
                    value={email}
                    onChange={handleEmailChange}
                    className="w-full md:w-60 p-2 md:ml-2 rounded-2xl border text-black border-white focus:outline-none focus:ring focus:border-blue-300"
                    disabled={submitting}
                  />
                  <button
                    className="bg-black md:ml-2 text-white w-full md:w-60 p-2 mt-2 rounded-2xl border border-white hover:bg-pink-600"
                    type="submit"
                    disabled={submitting}
                  >
                    {submitting ? 'Submitting...' : 'Join Waitlist'}
                  </button>
                </div>
                <p className="text-white">{message}</p>
              </form>
            </div>
          </div>
          <div className="mx-8 w-full md:w-1/2 mt-12">
            <img src={headerImage} alt="Right Item Image" className="w-full" />
          </div>
        </div>
      </div>

      <Modal showModal={showModal} onClose={toggleModal} />
    </>
  );
};

export default Header;
